@import '@/assets/scss/_mixins.scss';
@import '@/assets/scss/_variables.scss';

.CurrencyCard_root {
  height: 64px;
  @media only screen and (min-width: $tablet-width) {
    height: 90px;
  }
  position: relative;
  border-radius: 10px;
  background: linear-gradient(109.32deg, #2d4d70 27.32%, #213b57 54.09%, #14273c 98.91%);
  box-shadow: 0px 5px 16px rgba(34, 39, 47, 0.34);
  cursor: pointer;
  overflow: hidden;
  &.CurrencyCard_rootCrypto {
    background: radial-gradient(56.72% 56.72% at 50.28% 4.48%, #2d4d70 0%, #14273c 100%);

    .CurrencyCard_wrapper {
      position: absolute;
      left: 5px;
      top: 5px;
      right: 5px;
      bottom: 5px;

      .CurrencyCard_container {
        position: relative;
        height: 100%;
        width: 100%;
        border: 1px solid rgba(#2af3f3, 0.1);
        border-radius: 2px;
      }
    }
  }
  &--active {
    border: 1px solid #2AF3F3;
  }
}

.CurrencyCard_checked {
  position: absolute;
  right: 0;
  top: 0;
  width: 24px;
  height: 24px;
  background: #1d465d;  
  border-bottom-left-radius: 25%;

  span {
    display: block;
    width: 9px;
    height: 5px;
    border-left: 2px solid #2af3f3;
    border-bottom: 2px solid #2af3f3;
    border-bottom-left-radius: 1px;
    @include centeringWithTransform;
    transform: translate(-50%, -65%) rotate(-45deg);
  }
}

.CurrencyCard_textTitle {
  font-style: italic;
  font-weight: 900;
  font-size: 19px;
  line-height: 22px;
  color: #2af3f3;
}

.CurrencyCard_iconWrapper {
  @include centeringWithTransform;
 
  .CurrencyCard_root--imageFull & {
    width: 100%;
    height: 100%;
    padding: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (min-width: $tablet-width) {
      padding: 10px;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.CurrencyCard_iconWrapper {
  .SquareCoinIcon_root {
    background: none;
    .SquareCoinIcon_wrapper {
      width: 38px;
      height: 38px;
    }
  }
}
