@import '@/assets/scss/_variables.scss';

.Cashbox {
  .TextBtn_root {
    font-size: 12px;
    line-height: 14px;
    color: #2af3f3;
    margin-top: 10px;
    cursor: pointer;
    display: block;
    text-align: left;
    svg {
      margin-right: 2px;
      vertical-align: middle;
    }
  }
}

.Cashbox_form {
  flex-grow: 1;
  margin: 0 0 20px;
  @media only screen and (min-width: $tablet-width) {
    margin: 0 30px 0 0;
    max-width: 436px;
  }
}

.Cashbox_faq {
  @media only screen and (min-width: $tablet-width) {
    flex: 0 1 390px;
  }
}

.Cashbox_input {
  margin-bottom: 14px;
}

.Cashbox_submit {
  margin-top: 15px;
}
