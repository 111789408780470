@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';


































































































































.info-block {
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('~@/assets/icons/icon-decor-info-block.svg');
    width: 150px;
    height: 179px;
  }
}
.decor-dot {
  position: relative;
  padding: 0 0 0 15px;

  &:before {
    content: '';
    position: absolute;
    top: 6px;
    left: 0;
    width: 4px;
    height: 4px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 3px rgb(255 255 255 / 10%);
  }
}

.limits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 14px;
  margin: 11px 0 0;
}
