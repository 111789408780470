@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';


















































































.TextArea_root {
  width: 100%;

  .TextArea_label {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      text-align: left;
      color: #fff;
      margin-bottom: 10px;
    }

    &--bold {
      label {
        font-weight: 500;
      }
    }
  }
  .v-textarea.v-input--dense.v-text-field {
    width: 100%;

    .v-input__control {
      min-height: 46px;

      .v-input__slot {
        border: 1px solid transparent;
        background: rgba(#fff, 0.04);
        box-shadow: none;
        border-radius: 4px;
        font-size: 14px;
        line-height: 16px;
        color: #fff;
        .v-text-field__slot {
          textarea {
            caret-color: #fff;
          }
          textarea:-webkit-autofill,
          textarea:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
          }
          textarea:-webkit-internal-autofill {
            color: #fff !important;
          }
        }
      }
      .v-text-field__details {
        padding: 0;
        .v-messages.error--text {
          font-size: 14px;
          line-height: 16px;
          color: #ff556d;
        }
      }
    }
    &.error--text {
      .v-input__control {
        .v-input__slot {
          border: 1px solid #ff556d;
          background: rgba(255, 85, 109, 0.04);
          .v-input__append-inner {
            .v-input__icon {
              .v-icon {
                color: #ff556d;
              }
            }
          }
        }
      }
    }
  }
  &--hasShadow {
    .v-input.v-input--dense.v-text-field {
      .v-input__control {
        .v-input__slot {
          box-shadow: 0px 18px 21px rgba(34, 39, 47, 0.34);
        }
      }
    }
  }
}
